 /* eslint-disable */
<template>
  <b-card>

    <!-- media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <!-- <b-col sm="6">
          <b-form-group
            label="Username"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.username"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="email"
              name="email"
              placeholder="Email"
            />

          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group
            label="Company"
            label-for="account-company"
          >
            <b-form-input
              v-model="optionsLocal.company"
              name="company"
              placeholder="Company name"
            />
          </b-form-group>
        </b-col> -->

        <!-- alert -->
        <b-col
          cols="12"
          class="mt-75"
        />
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="change"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  // BFormFile,
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol,
  //  BAlert,
  BCard,
  // BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import axios from '@/axios'

export default {
  components: {
    BButton,
    BForm,
    // BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    // BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      profileFile: null,
      name: JSON.parse((localStorage.getItem('userData'))).name,
      email: JSON.parse((localStorage.getItem('userData'))).email,

    }
  },
  methods: {
    async change() {
      const token = localStorage.getItem('access-token')
      const credentials = {
        name: this.name,
        email: this.email,
        token,
      }
      await axios.post('/change', credentials).then(res => {
        if (res.status === 200) {
          // console.log(res)
          // alert('Done')
          this.$vs.notify({
            // title: "success",
            text: 'Done',
            // iconPack: "feather",
            // icon: "icon-alert-circle",
            color: '#425caa',
            time: 2000,
            // fixed: true
          })
          const { userData } = res.data
          localStorage.setItem('userData', JSON.stringify(userData))
        }
      })
      // .catch(console.log)
    },
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
